import React, { useState } from "react";

import PersonIcon from "@material-ui/icons/Person";
import isFunction from "lodash/isFunction";

import useRouteNavigation from "../../../../../hooks/RouteHooks/useRouteNavigation";
import { logError, logInfo } from "../../../../../services/logging";
import firebase from "../../../../../utils/firebase";
import { setLocalStore } from "../../../../../utils/storage/localStorage";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";

const FirebaseAnonymousSignIn = ({ setAuthError, isAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { redirectFlowNextRoute } = useRouteNavigation();

  const handleClick = () => {
    if (isAuthenticated) return;
    setAuthError("");
    setIsLoading(true);
    firebase
      .auth()
      .signInAnonymously()
      .then((result) => {
        const token = result?.credential?.accessToken;
        setLocalStore("idToken", token);
        const { user } = result;
        logInfo({ message: `AnonymousSignIn: user ${user.uid} login success` });

        // after authenticate process
        setTimeout(() => {
          if (window.authCallback && isFunction(window.authCallback)) {
            const isSuccess = window.authCallback();
            if (isSuccess) {
              window.authCallback = null;
              redirectFlowNextRoute();
            }
          }
        }, 1000);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setAuthError(errorMessage);
        // The email of the user's account used.
        const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        const { credential } = error;
        logError({
          message: "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
          error,
          credential,
          email,
          errorCode,
        });
        setIsLoading(false);
      });
  };
  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={<PersonIcon />}
      labelText="Continue as a guest"
      alt="Sign in as a guest"
      isLoading={isLoading}
      disabled={isAuthenticated}
    />
  );
};

export default FirebaseAnonymousSignIn;
