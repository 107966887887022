/* eslint-disable import/prefer-default-export */
import { getLocalStore, setLocalStore } from "../../utils/storage/localStorage";
import moment from "../../utils/time/moment";
import { getLocationIdByPathname } from "../../utils/utils";
import axiosClient from "../client";

const MAX_LOCAL_LOG_LENGTH = 20;

const getDefaultRecords = (deviceInfo) => ({
  "@timestamp": moment().format(),
  "location-id": getLocationIdByPathname(),
  "entity-id": getLocalStore("orderId"),
  "entity-ref": getLocalStore("displayRef"),
  "entity-type": "Order",
  "account-type": "public",
  "ext-account-id": getLocalStore("userId"),
  "account-name": getLocalStore("username"),
  sessionId: getLocalStore("sessionId"),
  osName: deviceInfo?.osName,
  osVersion: deviceInfo?.osVersion,
  browser: deviceInfo?.browserName,
  "browser-version": deviceInfo?.browserFullVersion,
});

const sendLogsToServer = (logList) => {
  const stringObjArray = logList.map((log) => JSON.stringify(log));
  const logString = stringObjArray.join("\n");
  axiosClient({
    url:
      "https://listener-eu.logz.io:8071/?token=MyDTkVyBuRlVZLlvPrpFImIVmOtnmYIC&type=eat-presto-frontend",
    method: "POST",
    data: logString,
  });
};

export const transferLogsServerAndClearLocalLogs = async () => {
  const logList = getLocalStore("logs");
  if (logList) {
    try {
      await sendLogsToServer(logList);
      setLocalStore("logs", []);
    } catch (error) {
      console.error("Error transfer logs");
      console.error(error);
    }
  }
};

const writeLogLocalStore = async (logRecord) => {
  let logList = getLocalStore("logs");
  if (logList) {
    if (logList.length >= MAX_LOCAL_LOG_LENGTH) {
      try {
        await sendLogsToServer(logList);
        setLocalStore("logs", []);
        logList = [logRecord];
        return setLocalStore("logs", logList);
      } catch (error) {
        console.error("Error transfer logs");
        console.error(error);
      }
    }
    logList = [...logList, logRecord];
    return setLocalStore("logs", logList);
  }
  logList = [logRecord];
  setLocalStore("logs", logList);
};

export const logInfo = (logRecord) => {
  console.log("sdfsdf45safsdfgsdgdfg", logRecord)
  const deviceInfo = getLocalStore("deviceInfo");
  const data = {
    ...getDefaultRecords(deviceInfo),
    level: "INFO",
    ...logRecord,
  };
  writeLogLocalStore(data);
};

export const logDebug = (logRecord) => {
  const deviceInfo = getLocalStore("deviceInfo");
  const data = {
    ...getDefaultRecords(deviceInfo),
    level: "DEBUG",
    ...logRecord,
  };
  writeLogLocalStore(data);
};

export const logError = (logRecord) => {
  const deviceInfo = getLocalStore("deviceInfo");
  const data = {
    ...getDefaultRecords(deviceInfo),
    level: "ERROR",
    ...logRecord,
  };
  writeLogLocalStore(data);
};

export const logWarn = (logRecord) => {
  const deviceInfo = getLocalStore("deviceInfo");
  const data = {
    ...getDefaultRecords(deviceInfo),
    level: "WARNING",
    ...logRecord,
  };
  writeLogLocalStore(data);
};
