/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { InView } from "react-intersection-observer";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import useQueryParam from "../../../../hooks/RouteHooks/useQueryParam";
import useMenuUtils from "../../../../hooks/useMenuUtils";
import {
  MENU_VIEW_TYPE_GRID,
  MENU_VIEW_TYPE_IMAGE_LIST,
  MENU_VIEW_TYPE_LIST,
  QUERY_PARAM_POPUP_CAT,
  QUERY_PARAM_POPUP_CATMENU,
  QUERY_PARAM_POPUP_MENU,
} from "../../../../utils/constants";
import { gaEventViewItem } from "../../../../utils/googleAnalytics";
import { showStoreHomeNotification } from "../../../../utils/order";
import CatMenu from "../CatMenu";
import { setCategorySelected } from "../Menu/redux/actions";
import LayoutGrid from "./layouts/LayoutGrid";
import LayoutImageList from "./layouts/LayoutImageList";
import LayoutList from "./layouts/LayoutList";
import MenuItemModal from "./MenuItemModal";
import { setCurrentPos, setTitlePosList } from "./redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100vh",
    color: theme.palette.text.titleTextColor,
    textAlign: "center",
    backgroundColor: theme.palette.background.menuBackgroundColor,
  },
  catTitle: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.menuBackgroundColor,
    color: theme.palette.text.subtitleTextColor,
    zIndex: 1000,
    padding: theme.spacing(1, 0),
  },
  scroll: {
    height: "100vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "none",
      // eslint-disable-next-line max-len
      height: ({ isOpenNow, isOrderingActive }) =>
        showStoreHomeNotification(isOpenNow, isOrderingActive)
          ? "calc(100vh - 250px)"
          : "calc(100vh - 170px)",
    },
    width: "100%",
    ...theme.palette.scrollbarStyles,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    backgroundColor: theme.palette.primary.main,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    // color: theme.palette.primary.contrastText,
    // "& .MuiInputBase-root": {
    //   color: theme.palette.primary.contrastText,
    //   borderRadius: theme.shape.borderRadius,
    // },
    // "& .MuiFormLabel-root": {
    //   color: theme.palette.primary.contrastText,
    // },
  },
}));

/**
 * This component renders a scrollable list of menu items with category titles.
 * It allows users to search for specific menu items and view details of selected items in a modal.
 */
function ItemListScroll({
  actions,
  category,
  setCurrentCat,
  item,
  currentCat,
  cartCount,
  searchName,
  setSearchName,
  setIsOpen,
  isOpen,
}) {
  const [openMenuItem, setOpenMenuItem] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const { isOpenNow, isOrderingActive, storeInfo } = useSelector(
    (state) => state.store,
  );
  const { selectedDeliveryMethod, selectedDateTime } = useSelector(
    (state) => state.cart,
  );
  const { catMenuList, catMenuOptions, activeCatMenu, catMenuListAll } =
    useSelector((state) => state.catMenu);
  const {
    theme: { itemDisplayType },
  } = useSelector((state) => state.initData);
  const { categorySelected } = useSelector((state) => state.category);
  const { embedType } = useSelector((state) => state.global);
  const classes = useStyles({ isOpenNow, isOrderingActive });
  const { updateParams, addParams, getParam } = useQueryParam();
  const { getMenuItemByQueryParams } = useMenuUtils();

  /**
   * The 'setOpenMenuItemFunc' function is responsible for opening the item modal with the selected menu item's details
   * and updating relevant state and query parameters.
   *
   * @param {Object} data - An object containing the details of the selected menu item.
   * @param {boolean} isOpenByQueryParams - A boolean value indicating whether the modal is being opened based on query parameters.
   */
  const setOpenMenuItemFunc = async (data, isOpenByQueryParams) => {
    // Check if the modal is not being opened by query parameters
    if (!isOpenByQueryParams) {
      // If not, update the query parameters with the selected menu item's details
      addParams({
        [QUERY_PARAM_POPUP_MENU]: data?.menuId,
        [QUERY_PARAM_POPUP_CAT]: data?.catId,
        [QUERY_PARAM_POPUP_CATMENU]: activeCatMenu.catMenuId,
      });
    }

    // Set the selected menu item data to be used in the modal
    await setSelectedItemData(data);

    // Set the 'openMenuItem' state to true to show the modal
    setOpenMenuItem(true);

    // Track the view event using Google Analytics
    gaEventViewItem(
      data,
      storeInfo.businessDisplayName,
      categorySelected,
      activeCatMenu,
    );
  };

  /**
   * This useEffect hook is responsible for calculating the positions of category titles on the page
   * and storing them in the state.
   * It runs when 'category.categoryList' or 'actions' change.
   */
  useEffect(() => {
    // Select all elements whose 'id' attribute starts with "itemSection-title-catId"
    let offs = document.querySelectorAll('[id^="itemSection-title-catId"]');

    // Convert the NodeList to an array for easier manipulation
    offs = Array.from(offs);

    // Check if there are any elements with the specified selector
    if (offs.length > 0) {
      // Map the elements to an array of objects containing position and category data
      offs = offs.map((e) => {
        const catData = {};
        // Get the offsetTop position of the element
        catData.pos = e.offsetTop;
        // Extract the category ID from the element's 'id' attribute
        catData.cat = category.categoryList.find(
          (c) => c.catId === e.id.split("_")[1],
        );
        return catData;
      });

      // Update the 'titlePosList' state with the array of category title positions
      actions.setTitlePosList(offs);
    }
  }, [category.categoryList, actions]);

  /**
   * This useEffect hook is responsible for handling the opening of the item modal if the query parameters are present in the URL.
   * It runs when 'catMenuListAll' changes.
   */
  useEffect(() => {
    // Check if 'catMenuListAll' is empty, and if so, return early since there are no menu items.
    if (_.isEmpty(catMenuListAll)) return;

    // Extract the 'menuItem' object from the query parameters using the 'getMenuItemByQueryParams' function.
    const { menuItem } = getMenuItemByQueryParams(catMenuListAll);

    // Check if 'menuItem' exists, indicating that the item modal should be opened.
    if (menuItem) {
      // Call 'setOpenMenuItemFunc' function to open the item modal with the specified 'menuItem' data.
      // The second argument 'true' indicates that the modal is being opened based on query parameters.
      setOpenMenuItemFunc(menuItem, true);
    }
  }, [catMenuListAll]);

  /**
   * This function handles the scroll event when a category title becomes visible in the viewport.
   * It is triggered by the Intersection Observer when a category title enters or leaves the viewport.
   *
   * @param {boolean} inView - A boolean value indicating whether the observed element is in the viewport or not.
   * @param {IntersectionObserverEntry} entry - The IntersectionObserverEntry object containing information about
   * the observed element.
   */
  const handleScroll = (inView, entry) => {
    // Destructure the 'firstChild' property from the 'entry.target' object.
    const { firstChild } = entry.target;

    // Check if the category title is in view (visible in the viewport).
    if (inView) {
      // Find the corresponding category data from 'titlesPosList' using the category ID
      // extracted from the category title's DOM ID.
      const cCat = item.titlesPosList.find(
        (tp) => tp.cat.catId === firstChild.id.split("_")[1],
      );

      // Call the 'setCurrentCat' function to update the current category state with the newly visible category.
      setCurrentCat(cCat);
    }
  };

  /**
   * The 'handleCloseItemModal' function is responsible for closing the item modal and
   * updating the query parameters accordingly. It sets the 'openMenuItem' state to false,
   * indicating that the modal should be closed,
   * and updates the query parameters related to the item modal to their initial values.
   */
  const handleCloseItemModal = () => {
    updateParams({
      [QUERY_PARAM_POPUP_CAT]: categorySelected?.catId,
      [QUERY_PARAM_POPUP_CATMENU]: activeCatMenu.catMenuId,
      [QUERY_PARAM_POPUP_MENU]: null,
    });
    setOpenMenuItem(false);
  };

  const catMenuId = getParam(QUERY_PARAM_POPUP_CATMENU);

  /**
   * The 'handleChangeSearch' function is a callback function that is triggered when the user types in the search input field.
   * It updates the 'searchName' state with the current input value and calls 'handleSearchDetails' function to perform the search.
   *
   * @param {object} e - The event object representing the user input event.
   */
  const handleChangeSearch = (e) => {
    // Extract the value of the input from the event object.
    const { value } = e.target;
    // Update the 'searchName' state with the current input value.
    setSearchName(value);
    // Call the 'handleSearchDetails' function to perform the search based on the input value.
    handleSearchDetails(value);
  };

  /**
   * The 'handleSearchDetails' function is responsible for filtering the menu items based on the provided search value.
   * It filters the 'catMenuList' based on the 'activeCatMenu.catMenuId' and then filters the menu items by title.
   * The filtered menu items are then stored in the 'filteredList' state.
   *
   * @param {string} value - The search value entered by the user.
   */
  const handleSearchDetails = (value) => {
    // Check if the search value is not empty and 'catMenuList' is available.
    if (!_.isEmpty(value) && catMenuList) {
      // Filter the 'catMenuList' to get the menu items for the active category menu.
      const menuItemsForActiveCategory = Object.values(catMenuList).filter(
        (item) => item.catMenuId === activeCatMenu.catMenuId,
      );

      // Check if the filtered list is not empty.
      if (!_.isEmpty(menuItemsForActiveCategory)) {
        // Get the data (menu items) from the first item of the filtered list.
        const activeCategoryMenuItems = Object.values(
          menuItemsForActiveCategory[0].data,
        );

        // Filter the menu items based on their title, ignoring case sensitivity.
        const filteredLists = activeCategoryMenuItems
          .map((menuItem) => ({
            ...menuItem,
            data: Object.fromEntries(
              Object.entries(menuItem.data).filter(([key, data]) =>
                data.title.toLowerCase().includes(value.toLowerCase()),
              ),
            ),
          }))
          .filter((menuItem) => Object.keys(menuItem.data).length > 0);

        // Update the 'filteredList' state with the filtered menu items.
        setFilteredList(filteredLists);
      }
    }
  };
  return (
    <Box className={classes.container}>
      {embedType !== "WP" && (
        <MenuItemModal
          open={openMenuItem}
          handleClose={handleCloseItemModal}
          selectedItemData={selectedItemData}
          catMenuId={catMenuId || activeCatMenu.catMenuId}
          selectedDeliveryMethod={selectedDeliveryMethod}
          selectedDateTime={selectedDateTime}
          catMenuOptions={catMenuOptions}
        />
      )}

      <CatMenu
        currentCat={currentCat}
        setCurrentCat={setCurrentCat}
        cartCount={cartCount}
        searchName={searchName}
        setSearchName={setSearchName}
        handleChangeSearch={handleChangeSearch}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {/* <Grid container>
        <Grid item xs={12} sm={6} style={{ margin: "16px" }}>
          <TextField
            classes={{ root: classes.textField }}
            id="outlined-inline-static"
            label="Search"
            variant="outlined"
            onChange={handleSearchChange}
            size="small"
            value={searchName}
            fullWidth
            InputProps={{
              endAdornment: <ClearButton />,
            }}
          />
        </Grid>
      </Grid> */}
      <Box id="items-container" className={classes.scroll}>
        {searchName ? (
          !_.isEmpty(filteredList) &&
          filteredList.map(
            (data1) =>
              data1.isActive && (
                <Box
                  key={`itemSection-title-${data1.catId}`}
                  style={{ marginTop: "1rem" }}
                >
                  <>
                    <InView
                      threshold={0}
                      as="div"
                      onChange={(inView, entry) => handleScroll(inView, entry)}
                    >
                      <Typography
                        id={`itemSection-title-catId_${data1.catId}`}
                        className={classes.catTitle}
                        variant="h5"
                        component="div"
                      >
                        {data1.title}
                      </Typography>
                      {itemDisplayType === MENU_VIEW_TYPE_LIST && (
                        <LayoutList
                          category={data1}
                          setOpenMenuItemFunc={setOpenMenuItemFunc}
                        />
                      )}
                      {itemDisplayType === MENU_VIEW_TYPE_GRID && (
                        <LayoutGrid
                          category={data1}
                          setOpenMenuItemFunc={setOpenMenuItemFunc}
                        />
                      )}
                      {itemDisplayType === MENU_VIEW_TYPE_IMAGE_LIST && (
                        <LayoutImageList
                          category={data1}
                          setOpenMenuItemFunc={setOpenMenuItemFunc}
                        />
                      )}
                    </InView>
                  </>
                </Box>
              ),
          )
        ) : (
          <Box
            key={`itemSection-title-${categorySelected?.catId}`}
            style={{ marginTop: "1rem" }}
          >{console.log("test category", categorySelected)}
            {category.categoryList.map((c) => (
              <>
                <InView
                  threshold={0}
                  as="div"
                  onChange={(inView, entry) => handleScroll(inView, entry)}
                >
                  <Typography
                    id={`itemSection-title-catId_${c.catId}`}
                    className={classes.catTitle}
                    variant="h5"
                    component="div"
                  >
                    {c.title}
                  </Typography>
                  {itemDisplayType === MENU_VIEW_TYPE_LIST && (
                    <LayoutList
                      category={c}
                      setOpenMenuItemFunc={setOpenMenuItemFunc}
                    />
                  )}
                  {itemDisplayType === MENU_VIEW_TYPE_GRID && (
                    <LayoutGrid
                      category={c}
                      setOpenMenuItemFunc={setOpenMenuItemFunc}
                    />
                  )}
                  {itemDisplayType === MENU_VIEW_TYPE_IMAGE_LIST && (
                    <LayoutImageList
                      category={c}
                      setOpenMenuItemFunc={setOpenMenuItemFunc}
                    />
                  )}
                </InView>
              </>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  category: state.category,
  item: state.item,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setTitlePosList, setCurrentPos, setCategorySelected },
    dispatch,
  ),
});

// function itemListPropsEqual(prevProps, nextProps) {
//   return (
//     prevProps.category.categoryList.length ===
//     nextProps.category.categoryList.length
//   );
// }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ItemListScroll));
