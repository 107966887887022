import * as React from "react";

import { isFunction } from "lodash";

import googleIcon from "../../../../../assets/images/googleSignIn/google.svg";
import useRouteNavigation from "../../../../../hooks/RouteHooks/useRouteNavigation";
import { logError, logInfo } from "../../../../../services/logging";
import firebase from "../../../../../utils/firebase";
import { setLocalStore } from "../../../../../utils/storage/localStorage";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST
  = "An account already exists with the same email address via Facebook sign-in. Please use Facebook to sign-in";

const GoogleSignIn = ({ setAuthError, isAuthenticated }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { redirectFlowNextRoute } = useRouteNavigation();

  const handleClick = () => {
    if (isAuthenticated) return;
    setAuthError("");
    setIsLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const token = result.credential.accessToken;
        setLocalStore("idToken", token);
        const { user } = result;
        logInfo({
          message: `GoogleSignIn: User [name: ${user.displayName}, uid: ${user.uid}]  login success`,
        });

        // after authenticate process
        setTimeout(() => {
          if (window.authCallback && isFunction(window.authCallback)) {
            window.authCallback();
            window.authCallback = null;
          }
          redirectFlowNextRoute();
        }, 1000);
      })
      .catch((error) => {
        // The email of the user's account used.
        const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        const { credential } = error;
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/account-exists-with-different-credential") {
          logError({
            message: ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST,
            error,
            errorCode,
            email,
            credential,
          });
          setIsLoading(false);
          return setAuthError(ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST);
        }

        setAuthError(errorMessage);
        setIsLoading(false);
        logError({
          message: "GoogleSignIn: Error login user with google sign in",
          error,
          credential,
          email,
          errorCode,
        });
      });
  };
  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={googleIcon}
      labelText="Sign in with Google"
      alt="google sign in"
      isLoading={isLoading}
      disabled={isAuthenticated}
    />
  );
};

export default GoogleSignIn;
